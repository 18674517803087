/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ButtonVariant, ListResponse } from '../../../types/general';
import {
    Invite,
    InviteParams,
    Member,
    MemberInviteFilters,
} from '../../../types/members';
import { MembersContext, withMembersContext } from '../../controllers/MembersContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { RolesContext, withRolesContext } from '../../controllers/RolesContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import Button from '../../elements/Button';
import { FloatingAddAction } from '../../elements/FloatingAddAction';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import InviteItem from '../../elements/InviteItem';
import { MemberCollapse } from '../../elements/organizations/MemberCollapse';
import { MemberInvitationForm } from '../../elements/organizations/MemberInvitationForm';
import Modal from '../../elements/Modal';
import { Role } from '../../../types/roles';
import { preparePageTitle } from '../../../utils/route';

interface OwnProps extends OrganizationsContext, MembersContext, RolesContext, TranslationContext { }

const MembersScreenComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        organizationSelected,
        getRoles,
        getMembers,
        getInvites,
        loadMemberRoles,
        t,
    } = props;
    const [searchParams] = useSearchParams();
    const inviteParam = searchParams.get('invite') ?? '';

    const [invites, setInvites] = useState<Invite[]>([]);
    const [filterSelected, setFilterSelected] = useState<MemberInviteFilters>(MemberInviteFilters.ALL);
    const [members, setMembers] = useState<Member[]>([]);
    const [roles, setRoles] = useState<ListResponse<Role>>({
        cursor: '',
        results: [],
    });
    const [openInviteModal, setOpenInviteModal] = useState(!!inviteParam);
    const [cursor, setCursor] = useState('');

    useEffect(() => {
        if (filterSelected === MemberInviteFilters.ALL) {
            setInvites([]);
            getMembersList();
            getRolesList();
        } else {
            setMembers([]);
            getInvitesList();
        }

        setCursor('');
    }, [filterSelected]);

    useEffect(() => {
        document.title = preparePageTitle(t('membersScreen.title'));
    }, []);

    const getInvitesList = async () => {
        const params: InviteParams = {
            _status: filterSelected,
            _cursor: cursor,
        };

        const invitesData = await getInvites(Number(organizationSelected?.organization?.id), params);

        if (!invitesData) return;

        setInvites(invitesData.results);
        setCursor(invitesData.cursor);
    };

    const getMembersList = async () => {
        const membersData = await getMembers(Number(organizationSelected?.organization?.id), cursor);

        if (!membersData) return;

        setMembers([...members, ...membersData.results]);
        setCursor(membersData.cursor);
    };

    const getRolesList = async () => {
        const rolesData = await getRoles(Number(organizationSelected?.organization?.id));

        if (!rolesData) return;

        setRoles(rolesData);
    };

    const handleOpenInvitationModal = () => {
        setOpenInviteModal(true);
    };

    const handleCloseInvitationModal = () => {
        setOpenInviteModal(false);
    };

    return (
        <div className="members-screen">
            <div className="members-screen__header">
                <h1>{t('membersScreen.title')}</h1>
                <Button
                    variant={ButtonVariant.Curved}
                    extraClasses="large-add-btn shorter-btn"
                    testId="btn-add-member-default"
                    onClick={handleOpenInvitationModal}
                >
                    {t('membersScreen.addNewMember')}
                </Button>
            </div>
            <div className="members-screen__wrap">
                <div className="members-screen__wrap__filters">
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.ALL ? 'selected' : ''}
                        onClick={() => setFilterSelected(MemberInviteFilters.ALL)}
                    >
                        {t(`membersScreen.${MemberInviteFilters.ALL}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.PENDING ? 'selected' : ''}
                        onClick={() => setFilterSelected(MemberInviteFilters.PENDING)}
                    >
                        {t(`membersScreen.${MemberInviteFilters.PENDING}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.ACCEPTED ? 'selected' : ''}
                        onClick={() => setFilterSelected(MemberInviteFilters.ACCEPTED)}
                    >
                        {t(`membersScreen.${MemberInviteFilters.ACCEPTED}`)}
                    </button>
                    <button
                        type="button"
                        className={filterSelected === MemberInviteFilters.REJECTED ? 'selected' : ''}
                        onClick={() => setFilterSelected(MemberInviteFilters.REJECTED)}
                    >
                        {t(`membersScreen.${MemberInviteFilters.REJECTED}`)}
                    </button>
                </div>
                {filterSelected !== MemberInviteFilters.ALL && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getInvitesList}
                    >
                        {invites.map((invite) => (
                            <InviteItem key={invite.id} invite={invite} />
                        ))}
                        {invites.length === 0 && (<p>{t('membersScreen.noPendingInvites')}</p>)}
                    </InfiniteScrollWrapper>
                )}
                {filterSelected === MemberInviteFilters.ALL && (
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getMembersList}
                    >
                        {members.map((member) => (
                            <MemberCollapse key={member.id} member={member} roles={roles} {...loadMemberRoles} />
                        ))}
                        {members.length === 0 && (<p>{t('membersScreen.noMembers')}</p>)}
                    </InfiniteScrollWrapper>
                )}
            </div>
            <FloatingAddAction type="button" onClick={handleOpenInvitationModal} testId="btn-add-member-mobile" />
            <Modal
                open={openInviteModal}
                handleClose={handleCloseInvitationModal}
                title={t('memberInvitationForm.title')}
            >
                <MemberInvitationForm roles={roles} handleCloseModal={handleCloseInvitationModal} />
            </Modal>
        </div>
    );
};

export const MembersScreen = withTranslationContext(withMembersContext(withOrganizationsContext(withRolesContext(MembersScreenComponent))));
