/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { difference } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentChecklistAssignment, WorkflowAssignment } from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';

import { LoadingCircles } from '../LoadingCircles';
import { UploadDocumentCard } from '../UploadDocumentCard';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { useWorkflowContext } from '../workflows/WorkflowContextProvider';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';

type OwnProps = WorkflowContext & TranslationContext;

const MissingDocumentListComponent: FunctionComponent<OwnProps> = (props) => {
    const { t, uploadWorkflowDocument, getWorkflowAssignments } = props;

    const { fetchDocuments } = useWorkflowContext();

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();
    
    const { user } = useAuthContext();

    const [documents, setDocuments] = useState<WorkflowAssignment[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [missingDocsCount, setMissingDocsCount] = useState(0);
    useEffect(() => {
        fetchWorkflowAssignments();
    }, []);

    const fetchWorkflowAssignments = async () => {
        setIsLoading(true);
        const [assignmentResponse, assignmentError] = await getWorkflowAssignments(workflowId, DocumentChecklistAssignment.UPLOAD, true);

        setIsLoading(false);
        if (assignmentError) {
            toast.error(assignmentError.errors[0].getMessageTranslated(t));

            return;
        }
        setMissingDocsCount(assignmentResponse.missingDocs);
        setDocuments(assignmentResponse.transactionAssignments.slice(0, 5));
    };

    const handleUploadDocument = async (documentFile: File | undefined, id?: string | number) => {
        if (!documentFile) return;

        const partsOfTheId = String(id).split('-');
        if (partsOfTheId.length !== 2) return;

        const participantRole = partsOfTheId[0] || '';
        const documentTypeId = Number(partsOfTheId[1]) ?? 0;

        const document = documents.find((d) => d.participantRole === participantRole && d.documentTypeId === documentTypeId);

        if (!document) return;

        const uploadError = await uploadWorkflowDocument(workflowId, {
            documentType: document.documentTypeId,
            file: documentFile,
            name: document.documentTypeName,
            notes: '',
            userId: user?.id === document.participantId ? undefined : document.participantId,
        });

        if (!uploadError) {
            setDocuments((prev) => difference(prev, [document]));
            fetchDocuments(true);

            return;
        }

        toast.error(uploadError.errors[0].getMessageTranslated(t));
    };

    return (
        <div className="workflow-screen--view__section-elements__missing-documents">
            <div className="workflow-screen--view__section-elements__missing-documents__header">
                <div className="workflow-screen--view__section-elements__missing-documents__header__title">
                    {t('workflows.view.missingDocuments')}
                    <span className="count">({missingDocsCount})</span>
                </div>
            </div>
            {isLoading && <LoadingCircles size="m" variant="primary" />}
            {!isLoading && documents.length === 0 && (<p>{t('workflows.view.noResultsMissingDocuments')}</p>)}
            {!isLoading && documents.length > 0 && (
                <>
                    <div className="upload-document-card-list">
                        {documents.map((d) => (
                            <UploadDocumentCard
                                key={`${d.participantRole}-${d.documentTypeId}`}
                                document={{
                                    id: `${d.participantRole}-${d.documentTypeId}`,
                                    title: d.documentTypeCode ? t(`workflowDocumentTypes.${d.documentTypeCode}`) : d.documentTypeName,
                                    description: t('workflows.documents.waitingUploadFrom', { name: t(`participantRole.${d.participantRole}`) }),
                                }}
                                onUploadFileCallback={handleUploadDocument}
                            />
                        ))}
                    </div>
                    <Button
                        variant={ButtonVariant.RectangularStroked}
                        extraClasses="full-width"
                        onClick={() => navigate(buildUrl(AppRoute.WorkflowMissingDocuments, { workflowId }))}
                    >
                        {t('workflows.view.viewAll')}
                    </Button>
                </>
            )}
        </div>
    );
};

export const MissingDocumentList = withTranslationContext(withWorkflowContext(MissingDocumentListComponent));
