/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ValueOf } from './general';

export const ContractPermissions = {
    NONE: 'NONE',
    VIEW_CONTRACT: 'VIEW_CONTRACT',
    MANAGE_CONTRACT: 'VIEW_CONTRACT',
    MANAGE_ORGANIZATION_CONTRACTS: 'MANAGE_ORGANIZATION_CONTRACTS',
    VIEW_ORGANIZATION_CONTRACTS: 'VIEW_ORGANIZATION_CONTRACTS',
    MANAGE_ALL_ORGANIZATION_CONTRACTS: 'MANAGE_ALL_ORGANIZATION_CONTRACTS',
    VIEW_ALL_ORGANIZATION_CONTRACTS: 'VIEW_ALL_ORGANIZATION_CONTRACTS',
    SIGN_ORGANIZATION_CONTRACTS: 'SIGN_ORGANIZATION_CONTRACTS',
} as const;

export const ContractTypePermissions = {
    NONE: 'NONE',
    MANAGE_CONTRACT_TYPE: 'MANAGE_CONTRACT_TYPE',
} as const;

export const OrganizationPermissions = {
    NONE: 'NONE',
    MANAGE_ORGANIZATION: 'MANAGE_ORGANIZATION',
} as const;

export const RolePermissions = {
    NONE: 'NONE',
    VIEW_ROLE: 'VIEW_ROLE',
    MANAGE_ROLE: 'MANAGE_ROLE',
} as const;

export const OrganizationAccessPermissions = {
    NONE: 'NONE',
    MANAGE_ORGANIZATION_ACCESS: 'MANAGE_ORGANIZATION_ACCESS',
} as const;

export const OrganizationInvitePermissions = {
    NONE: 'NONE',
    VIEW_ORGANIZATION_INVITE: 'VIEW_ORGANIZATION_INVITE',
    MANAGE_ORGANIZATION_INVITE: 'MANAGE_ORGANIZATION_INVITE',
} as const;

export const DocumentTypePermissions = {
    NONE: 'NONE',
    MANAGE_DOCUMENT_TYPE: 'MANAGE_DOCUMENT_TYPE',
} as const;

export const TransactionPermissions = {
    NONE: 'NONE',
    VIEW_ORGANIZATION_TRANSACTIONS: 'VIEW_ORGANIZATION_TRANSACTIONS',
    VIEW_ALL_ORGANIZATION_TRANSACTIONS: 'VIEW_ALL_ORGANIZATION_TRANSACTIONS',
    MANAGE_ORGANIZATION_TRANSACTIONS: 'MANAGE_ORGANIZATION_TRANSACTIONS',
    MANAGE_ALL_ORGANIZATION_TRANSACTIONS: 'MANAGE_ALL_ORGANIZATION_TRANSACTIONS',
    REVIEW_ALL_ORGANIZATION_TRANSACTIONS: 'REVIEW_ALL_ORGANIZATION_TRANSACTIONS',
    REVIEW_ORGANIZATION_TRANSACTIONS: 'REVIEW_ORGANIZATION_TRANSACTIONS',
    VIEW_TRANSACTION_DOCUMENT: 'VIEW_TRANSACTION_DOCUMENT',
    MANAGE_TRANSACTION_DOCUMENT: 'MANAGE_TRANSACTION_DOCUMENT',
    UPLOAD_TRANSACTION_DOCUMENT: 'UPLOAD_TRANSACTION_DOCUMENT',
    VIEW_TRANSACTION_CONTRACT: 'VIEW_TRANSACTION_CONTRACT',
    MANAGE_TRANSACTION_CONTRACT: 'MANAGE_TRANSACTION_CONTRACT',
    SIGN_TRANSACTION_CONTRACT: 'SIGN_TRANSACTION_CONTRACT',
    
} as const;

export const Permissions = {
    ...ContractPermissions,
    ...ContractTypePermissions,
    ...OrganizationPermissions,
    ...RolePermissions,
    ...OrganizationAccessPermissions,
    ...OrganizationInvitePermissions,
    ...TransactionPermissions,
    NONE: 'NONE',
} as const;

export type PermissionList = ValueOf<typeof Permissions>[];
