/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

/* Contract Type */
export const contractTypesUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/contract-types${queryStr}`;
};

export const contractTypeUrl = (contractTypeId: number) => {
    return `/contract-types/${contractTypeId}`;
};

/* Contract */
export const contractsUrl = () => {
    return '/contracts';
};

export const contractsListUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/contracts/list${queryStr}`;
};

export const contractUrl = (contractId: string) => {
    return `/contracts/${contractId}`;
};

export const contractPdfUrl = (contractId: string) => {
    return `/contracts/${contractId}/download`;
};

export const contractCancelUrl = (contractId: string) => {
    return `/contracts/${contractId}/void`;
};

export const purchaseContractUrl = (contractId: string) => {
    return `/contracts/${contractId}/purchase`;
};

/* Signers */
export const signersUrl = (contractId: string) => {
    return `/contract/${contractId}/signer`;
};

export const signerUrl = (contractId: number, signerId: number) => {
    return `/contract/${contractId}/signer/${signerId}`;
};

/* Placeholders */
export const placeholdersUrl = (contractId: string) => {
    return `/contracts/${contractId}/placeholders`;
};

export const placeholderUrl = (contractId: string, placeholderId: number) => {
    return `/contracts/${contractId}/placeholders/${placeholderId}`;
};

export const assignUserToSignerUrl = (signerToken: string) => {
    return `/signer/${signerToken}/assign`;
};

export const signerContractUrl = (signerToken: string) => {
    return `/signer/${signerToken}/contract`;
};

/* Signature */
export const signatureUrl = () => {
    return '/signature';
};
