/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent,
    FunctionComponent,
    Suspense,
    lazy,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { DataOrError, ErrorCode, FunctionalError } from '../../../types/errors';
import { SignerInvitationContext, withSignerInvitationContext } from '../../controllers/SignerInvitationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { Contract } from '../../../types/contracts';
import { ReactComponent as ErrorIcon } from '../../../assets/images/not_found.svg';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { LoadingScreen } from '../LoadingScreen';
import { SignerInvitationLayout } from '../../elements/contracts/layouts/SignerInvitationLayout';
import { SmartCodeFormField } from '../../elements/SmartCodeFormField';
import { WEBAPP_URL } from '../../../settings';
import { buildUrl } from '../../../utils/navigation';
import TextBadge from '../../elements/TextBadge';
import { BadgeTextSize, TextBadgeColor } from '../../../types/textBadge';

const PdfViewerWrapper = lazy(() => import('../../elements/pdfViewer/PdfViewerWrapper'));

interface OwnProps extends TranslationContext, SignerInvitationContext {}

type Mode = 'init' | 'firstAssigning' | 'assignSuccess' | 'assignFailedRequestPIN' | 'validatingPin' | 'userCannotBeAssignedWhatsoever';

const UnrecoverableErrors = [ErrorCode.INVALID_SIGNER_ASSIGNMENT_BY_EMAIL.toString(), ErrorCode.SIGNER_INVITE_EMAIL_ALREADY_TAKEN.toString()];
const ErrorsPromptingPinValidation = [ErrorCode.INVALID_SIGNER_ASSIGNMENT_BY_EMAIL.toString()];
const ErrorsAllowingToStillSucceed = [ErrorCode.SIGNER_USER_ALREADY_ASSIGNED.toString(), ErrorCode.USER_IS_ALREADY_CONTRACT_SIGNER.toString()];

export const SignerInvitationClaimCallbackBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        loadContractInformation,
        tryAssigningUserToSigner,
        validatePIN,
    } = props;
    const navigate = useNavigate();
    const { signerToken } = useParams();
    const [mode, setMode] = useState<Mode>('init');
    const [contractApiResult, setContractApiResult] = useState<DataOrError<Contract | undefined>>([undefined, null]);
    const [contractData] = contractApiResult;
    const [pinSubmissionResult, setPinSubmissionResult] = useState<DataOrError<Contract | undefined>>([undefined, null]);
    const [, pinError] = pinSubmissionResult;
    const pdfPagesListRef = useRef<VariableSizeList>();

    /**
     * Escape hatch if user encounters an error
     */
    function goToHomePage() {
        navigate(AppRoute.Index);
    }

    /**
     * Handles PIN submission and validation
     * @param e
     */
    function handleSubmitUnlockingPIN(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        setMode('validatingPin');
        validatePIN(signerToken!, {
            authenticationPIN: formData.get('pin')?.toString() ?? '',
        }).then((res) => {
            setPinSubmissionResult(res);
            const [data, error] = res;
            if (data) {
                setMode('assignSuccess');
                return;
            }
            if (error instanceof FunctionalError && UnrecoverableErrors.includes(error.errorCode.toString())) {
                setMode('userCannotBeAssignedWhatsoever');
                return;
            }

            setMode('assignFailedRequestPIN');
        });
    }

    /**
     * After successful assign, redirect to the signing page if the signer data is available. Otherwise fallback redirecting to ShowContract page
     * @returns
     */
    function handleOnSuccessUserAssignToSigner() {
        const signerData = contractData?.signers?.find((s) => s.inviteToken === signerToken);
        if (signerData?.id) {
            navigate(buildUrl(AppRoute.ShowContract, {
                contractId: String(contractData?.id),
                signerId: String(signerData?.id),
            }));
            return;
        }
        navigate(buildUrl(AppRoute.ShowContract, {
            contractId: String(contractData?.id),
        }));
    }

    /**
     * Component initialization logic
     */
    function handleInitMode() {
        if (signerToken) {
            loadContractInformation(signerToken).then((res) => {
                const [successData] = res;
                if (successData) {
                    setMode('firstAssigning');
                    setContractApiResult(res);
                }
            });
        }
    }

    /**
     * Logic to attempt first assignment to signer
     */
    function handleFirstAssigningMode() {
        if (signerToken) {
            tryAssigningUserToSigner(signerToken).then((res) => {
                const [, error] = res;
                if (error instanceof FunctionalError) {
                    if (ErrorsAllowingToStillSucceed.includes(error?.errorCode?.toString())) {
                        setMode('assignSuccess');
                        return;
                    }
                    if (ErrorsPromptingPinValidation.includes(error?.errorCode?.toString())) {
                        setMode('assignFailedRequestPIN');
                        return;
                    }
                }
                if (error instanceof Error) {
                    setMode('userCannotBeAssignedWhatsoever');
                    return;
                }
                setMode('assignSuccess');
            });
        }
    }

    useEffect(() => {
        if (signerToken) {
            switch (mode) {
                case 'init':
                    handleInitMode();
                    break;
                case 'firstAssigning':
                    handleFirstAssigningMode();
                    break;
                case 'assignSuccess':
                    handleOnSuccessUserAssignToSigner();
                    break;
                case 'assignFailedRequestPIN':
                default:
                    break;
            }
        }
    }, [signerToken, mode]);

    return (
        <Suspense fallback={<LoadingScreen />}>
            <SignerInvitationLayout>
                <div className="signer-invitation-claim-callback-screen light-gradient-bg information-page">
                    <div className="signer-invitation-claim-callback-screen__contract-data">
                        {contractData?.id && (
                            <TextBadge
                                text={String(contractData?.id)}
                                color={TextBadgeColor.Purple}
                                fontSize={BadgeTextSize.Small}
                                mediumPadding
                            />
                        )}
                        <h1 className="signer-invitation-claim-callback-screen__contract-data__name">{contractData?.name}</h1>
                        <span className="signer-invitation-claim-callback-screen__contract-data__type">{contractData?.contractType?.name}</span>
                    </div>
                    <div className="signer-invitation-claim-callback-screen__pdf-viewer pdf-viewer-wrap canvas-blur" data-testid="signer-invitation-viewer">
                        <div className="signer-invitation-claim-callback-screen__blocking-overlay">
                            {mode === 'init' && !signerToken && (
                                <>
                                    <ErrorIcon />
                                    <h2>{t('signerInvitationClaimCallback.initWithoutValidToken')}</h2>
                                    <Button variant={ButtonVariant.Basic} extraClasses="primary" onClick={() => goToHomePage()}>{t('signerInvitationClaimCallback.backToHome')}</Button>
                                </>
                            )}

                            {mode === 'firstAssigning' && (
                                <>
                                    <h2>{t('signerInvitationClaimCallback.firstAssigningLoading')}</h2>
                                    <LoadingCircles size="m" variant="primary" />
                                </>
                            )}

                            {mode === 'assignSuccess' && (
                                <>
                                    <h2>{t('signerInvitationClaimCallback.assignSuccessRedirect')}</h2>
                                    <LoadingCircles size="m" variant="primary" />
                                </>
                            )}

                            {mode === 'assignFailedRequestPIN' && (
                                <form onSubmit={handleSubmitUnlockingPIN}>
                                    <h2>{t('signerInvitationClaimCallback.assignFailedRequestPinTitle')}</h2>
                                    <p>{t('signerInvitationClaimCallback.assignFailedRequestPinDescription', {
                                        email: contractData?.signers?.[0]?.inviteEmail,
                                    })}
                                    </p>
                                    <SmartCodeFormField id="pin" name="pin" size={4} hasError={Boolean(pinError)} />
                                    <p className="field-error">{pinError?.message}</p>
                                    <div id="pin-actions">
                                        <Button variant={ButtonVariant.Basic} extraClasses="primary" isSubmit>{t('signerInvitationClaimCallback.submitPin')}</Button>
                                        <Button variant={ButtonVariant.Basic} extraClasses="secondary" onClick={() => goToHomePage()}>{t('signerInvitationClaimCallback.backToHome')}</Button>
                                    </div>

                                </form>
                            )}

                            {mode === 'validatingPin' && (
                                <>
                                    <h2>{t('signerInvitationClaimCallback.validatingPin')}</h2>
                                    <LoadingCircles size="m" variant="primary" />
                                </>
                            )}

                            {mode === 'userCannotBeAssignedWhatsoever' && (
                                <>
                                    <ErrorIcon />
                                    <h2>{t('signerInvitationClaimCallback.userCannotBeAssignedWhatsoever')}</h2>
                                    <p className="field-error">{pinError?.message}</p>
                                    <Button variant={ButtonVariant.Basic} extraClasses="secondary" onClick={() => goToHomePage()}>{t('signerInvitationClaimCallback.backToHome')}</Button>
                                </>
                            )}
                        </div>
                        <div className="pdf-viewer-max-width-content">
                            <PdfViewerWrapper
                                fileUrl={`${WEBAPP_URL}/docs/unauthenticated-contract.pdf`}
                                pdfPagesListRef={pdfPagesListRef}
                                placeholderList={[]}
                                updatePlaceholderList={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </SignerInvitationLayout>

        </Suspense>
    );
};

export const SignerInvitationClaimCallbackScreen = withSignerInvitationContext(withTranslationContext(SignerInvitationClaimCallbackBase));
