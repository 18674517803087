/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { LoadingScreen } from '../../screens/LoadingScreen';
import { MenuBar } from '../MenuBar';
import { NavBar } from '../NavBar';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { TopNotificationAlert } from '../TopNotificationAlert';

interface OwnProps extends TranslationContext{
    children: ReactNode;
    primaryBtn?: ReactNode;
    secondaryBtn?: ReactNode;
    isLoading?: boolean;
    customMenu?: ReactNode;
}
const DefaultLayoutBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        children,
        primaryBtn,
        secondaryBtn,
        isLoading = false,
        customMenu,
    } = props;

    return (
        <>
            <TopNotificationAlert>
                {t('alert.cmdOffline')} <a target="_blank" href="https://tek.sapo.pt/noticias/internet/artigos/servicos-de-autenticacao-e-website-da-ama-estao-offline-o-que-se-passa?utm_source=SAPO_HP&utm_medium=web&utm_campaign=destaques" rel="noreferrer">{t('alert.readMore')}</a>
            </TopNotificationAlert>
            <div className="default-layout">
                <NavBar />
                {customMenu || <MenuBar />}
                {isLoading && <LoadingScreen />}
                {!isLoading && (
                    <>
                        <div className="default-layout__content" data-testid="default-layout">
                            {children}
                        </div>
                        {(secondaryBtn || primaryBtn) && (
                            <footer className="default-layout__footer" data-testid="footer">
                                <div className="default-layout__footer__btns-wrap">
                                    {secondaryBtn}
                                    {primaryBtn}
                                </div>
                            </footer>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export const DefaultLayout = withTranslationContext(DefaultLayoutBase);
