/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Contract } from '../../../types/contracts';
import { preparePageTitle } from '../../../utils/route';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import ContractCard from '../../elements/contracts/ContractCard';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Permissions } from '../../../types/permissions';
import { AppRoute } from '../../../constants/routes';
import { Button } from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { FloatingAddAction } from '../../elements/FloatingAddAction';

interface OwnProps extends TranslationContext, ContractsContext { }

export const ContractsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getContracts,
    } = props;

    const {
        user,
    } = useAuthContext();
    
    const userHasPermissions = useUserHasPermission([Permissions.VIEW_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.VIEW_ALL_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);

    const [contracts, setContracts] = useState<Contract[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');

    useEffect(() => {
        document.title = preparePageTitle(t('contracts.title'));
        if (user) {
            getContractsList(cursor);
        }
    }, [user]);

    const getContractsList = async (c: string) => {
        setIsLoading(true);

        const data = await getContracts(c);

        setIsLoading(false);
        setContracts([...contracts, ...data.results]);
        setCursor(data.cursor);
    };

    return (
        <DefaultLayout>
            <div className="contract-screen" data-testid="contracts-screen">
                <div className="contract-screen__header">
                    <h1>{t('contracts.title')}</h1>
                    {userHasPermissions && (
                        <Link to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }}>
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="large-add-btn shorter-btn"
                                testId="large-add-btn"
                            >
                                {t('contracts.createBtn')}
                            </Button>
                        </Link>
                    )}
                </div>
                <div className="contract-screen__list">
                    <p className="contract-screen__list__BETA-migration-warning">{t('contracts.BETA_migrationWarning')}</p>
                    {!isLoading && contracts.length === 0 && (
                        <p className="contract-screen__list__empty-list">{t('contracts.noResults')}</p>
                    )}
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={() => getContractsList(cursor)}
                    >
                        {contracts.map((contract) => (
                            <ContractCard key={contract.id} contract={contract} />
                        ))}
                    </InfiniteScrollWrapper>
                </div>
                {isLoading && <LoadingCircles size="m" variant="primary" />}
                {userHasPermissions && (
                    <FloatingAddAction type="link" to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }} testId="add-btn" />
                )}
            </div>
        </DefaultLayout>
    );
};

export const ContractsScreen = withTranslationContext(withContractsContext(ContractsScreenComponent));
