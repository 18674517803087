/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoWithText } from '../../../../assets/images/logo-with-text.svg';
import { ReactComponent as SignIcon } from '../../../../assets/images/pen.svg';
import { AppRoute } from '../../../../constants/routes';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { TopNotificationAlert } from '../../TopNotificationAlert';

interface OwnProps extends TranslationContext{
    children: ReactNode;
    primaryBtn?: ReactNode;
    secondaryBtn?: ReactNode;
}

const EsignaturesLayoutBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        children,
        primaryBtn,
        secondaryBtn,
    } = props;
    return (
        <>
            <TopNotificationAlert>
                {t('alert.cmdOffline')} <a target="_blank" href="https://tek.sapo.pt/noticias/internet/artigos/servicos-de-autenticacao-e-website-da-ama-estao-offline-o-que-se-passa?utm_source=SAPO_HP&utm_medium=web&utm_campaign=destaques" rel="noreferrer">{t('alert.readMore')}</a>
            </TopNotificationAlert>
            <div className="esignatures-layout" data-testid="esignatures-layout">
                <header className="esignatures-layout__header" data-testid="header">
                    <Link to={AppRoute.Index}>
                        <div className="esignatures-layout__header__logo-wrap">
                            <LogoWithText />
                        </div>
                    </Link>
                    <div className="esignatures-layout__header__icon-wrap">
                        <SignIcon />
                    </div>
          
                </header>
                <div className="esignatures-layout__content">
                    {children}
                </div>
                {(secondaryBtn || primaryBtn) && (
                    <footer className="esignatures-layout__footer" data-testid="footer">
                        <div className="esignatures-layout__footer__btns-wrap">
                            {secondaryBtn}
                            {primaryBtn}
                        </div>
                    </footer>
                )}
            </div>
        </>
    );
};

export const EsignaturesLayout = withTranslationContext(EsignaturesLayoutBase);
