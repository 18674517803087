/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ContractsSvg } from '../../assets/images/businesses.svg';
import { ReactComponent as HomeSvg } from '../../assets/images/home.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg';
import { ReactComponent as OrganizationSvg } from '../../assets/images/people.svg';
import { ReactComponent as BusinessesSvg } from '../../assets/images/work-bag.svg';
import { AppRoute } from '../../constants/routes';
import { MenuBarItem } from '../../types/menu';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

type OwnProps = TranslationContext & OrganizationsContext;

const MenuBarComponent: FunctionComponent<OwnProps> = (props) => {
    const { organizationSelected, t } = props;

    const menuBarItems: MenuBarItem[] = organizationSelected?.organization
        ? [
            {
                title: 'home',
                icon: <HomeSvg />,
                link: AppRoute.Index,
            },
            {
                title: 'organization',
                icon: <OrganizationSvg />,
                link: AppRoute.OrganizationBillingDashboard,
            },
            {
                title: 'contracts',
                icon: <ContractsSvg />,
                link: AppRoute.Contracts,
            },
            {
                title: 'workflows',
                icon: <BusinessesSvg />,
                link: AppRoute.OrganizationWorkflows,
            },
        ] : [
            {
                title: 'home',
                icon: <HomeSvg />,
                link: AppRoute.Index,
            },
            {
                title: 'contracts',
                icon: <ContractsSvg />,
                link: AppRoute.Contracts,
            },
            {
                title: 'workflows',
                icon: <BusinessesSvg />,
                link: AppRoute.Workflows,
            },
        ];

    return (
        <div className="menu-bar" data-testid="menu-bar">
            <div className="menu-bar__logo-wrap_desktop">
                <Logo />
            </div>
            <div className="menu-bar__wrap-list">
                <ul className="menu-bar__wrap-list__list dark-custom-scrollbar">
                    {menuBarItems.map((item) => (
                        <Tooltip key={item.title} title={t(`menuBar.${item.title}`)} placement="bottom">
                            <NavLink to={item.link} end>
                                <li className="menu-bar__list__item">
                                    <div className="menu-bar__list__item__icon-wrap">
                                        {item.icon}
                                    </div>
                                    <span className="menu-bar__list__item__title">
                                        {t(`menuBar.${item.title}`)}
                                    </span>
                                </li>
                            </NavLink>
                        </Tooltip>
                    ))}
                </ul>
            </div>
            {/* Bottom for now is just for styling purpose */}
            <div className="menu-bar__bottom" />
        </div>
    );
};

export const MenuBar = withOrganizationsContext(withTranslationContext(MenuBarComponent));
