/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Avatar,
    Button,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BadgeTextSize, TextBadgeColor } from '../../../types/textBadge';
import { Contract, ContractState, ContractStateNumeric } from '../../../types/contracts';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/arrow-down-broken.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { ReactComponent as OwnerIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as PendingIcon } from '../../../assets/images/pending.svg';
import { ReactComponent as PeopleSvg } from '../../../assets/images/people.svg';
import TextBadge from '../TextBadge';
import { buildUrl } from '../../../utils/navigation';
import { useAuthContext } from '../../controllers/AuthenticationContext';

interface OwnProps extends TranslationContext {
    contract: Contract;
}

const ContractHappyPath = [
    ContractState.DRAFT,
    ContractState.SIGNING,
    ContractState.SIGNED,
];
const ContractCard: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        contract,
    } = props;
    const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

    const isOpenSignersMenu = Boolean(anchorMenu);
    const { user } = useAuthContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorMenu(event.currentTarget);
    };

    const handleClose = (event: { stopPropagation: () => void }) => {
        setAnchorMenu(null);
        event.stopPropagation();
    };

    const signerIsCurrentUser = () => {
        return contract.signers?.some((signer) => signer.inviteEmail === user?.email && !signer.signedAt);
    };
    const currentStateInHappyPath = ContractHappyPath.some((step) => step === contract.contractState);

    const getOpenContractUrl = () => {
        switch (contract.contractState) {
            case ContractState.DRAFT:
                return buildUrl(AppRoute.DraftContract, { contractId: String(contract.id) });
            case ContractState.SIGNING:
            case ContractState.SIGNED:
            default:
                return buildUrl(AppRoute.ShowContract, { contractId: String(contract.id) });
        }
    };

    return (
        <Link
            to={getOpenContractUrl()}
            key={`link-${contract.id}`}
        >
            <div
                className={classNames(
                    'contract-card card',
                    { 'user-is-signer': signerIsCurrentUser() && contract.contractState === ContractState.SIGNING },
                )}
                data-testid="card"
            >
                <div className="contract-card__header">
                    <TextBadge
                        text={contract.id.toString()}
                        color={TextBadgeColor.Gray}
                        fontSize={BadgeTextSize.Small}
                        mediumPadding
                    />
                    <div className="contract-card__header__right">
                        <div className="contract-card__header__right__contract-type">
                            {contract.contractType.name}
                        </div>
                        <div className="contract-card__header__right__owner">
                            <Avatar alt="owner avatar" sizes="20">
                                <OwnerIcon height={14} />
                            </Avatar>
                        </div>
                    </div>
                </div>
                <h3 className="contract-card__title">{contract.name}</h3>
                <div className="contract-card__info">
                    <div className="contract-card__info__left">
                        {
                            // to connect with business
                            // printMonetaryValue(business.price, business.currency)
                            '-'
                        }
                    </div>
                    <div />
                    <div className="contract-card__info__right">
                        <Button
                            className="contract-card__info__right__signers-btn"
                            aria-controls={isOpenSignersMenu ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isOpenSignersMenu ? 'true' : undefined}
                            onClick={handleClick}
                            data-testid="signers-btn"
                        >
                            <PeopleSvg />
                            <span data-testid="signers-number">{contract.signers?.length ?? 0}</span>
                            {contract.signers?.length > 0 && <ArrowDownIcon />}
                        </Button>
                        {contract.signers?.length > 0 && (
                            <Menu
                                className="contract-card__info__right__signers-menu"
                                anchorEl={anchorMenu}
                                open={isOpenSignersMenu}
                                onClose={handleClose}
                                onClick={(e) => e.stopPropagation()}
                                MenuListProps={{
                                    'aria-labelledby': 'signers-menu',
                                }}
                            >
                                {contract.signers?.map((signer) => (
                                    <ListItem key={signer.inviteEmail}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {signer.signedAt ? <CheckIcon /> : <PendingIcon />}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={signer.name}
                                            secondary={
                                            signer.signedAt
                                                ? t('signerStates.signed')
                                                : t('signerStates.notSigned')
                                        }
                                        />
                                    </ListItem>
                                ))}
                            </Menu>
                        )
                    }
                    </div>
                </div>
                {currentStateInHappyPath
                    ? (
                        <>
                            <LinearProgress
                                value={ContractStateNumeric[contract.contractState]}
                                variant="determinate"
                                color="primary"
                            />
                            <div className="contract-card__state">
                                {signerIsCurrentUser() && contract.contractState === ContractState.SIGNING
                                    ? t('contracts.requiresYourSignature')
                                    : t(`contractStates.${contract.contractState}`)}
                            </div>
                        </>
                    ) : <div className="contract-card__state-not-happy-path">{t(`contractStates.${contract.contractState}`)}</div>}
            </div>
        </Link>
    );
};

export default withTranslationContext(ContractCard);
