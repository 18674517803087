/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import { FunctionComponent, ReactNode } from 'react';

interface OwnProps {
    children: string | ReactNode;
}

type Props = OwnProps;

const TopNotificationAlert: FunctionComponent<Props> = (props: Props) => {
    const {
        children,
    } = props;

    return (
        <div
            className="top-notification-alert"
            data-testid="top-notification-alert"
        >
            <div className="top-notification-alert__content">
                <span>
                    {children}
                </span>
            </div>
        </div>
    );
};

export { TopNotificationAlert };
