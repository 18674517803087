/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QesSignatureContext, QesSignatureContextProvider } from './QesSignatureContext';
import { TranslationContext, withTranslationContext } from '../TranslationContext';
import {
    acceptDocumentationUrl,
    documentationUrl,
    qesCompleteSignature,
    qesVideoFlowUrl,
    qesVideoVerificationUrl,
    startQesWorkflowUrl,
} from '../../../services/esignatures/qes';

import { AppRoute } from '../../../constants/routes';
import { QesVideoDocumentType } from '../../../types/esignatures/qes';
import { buildUrl } from '../../../utils/navigation';
import { languageTypes } from '../../../types/preferences';
import { useCornerstoneApi } from '../../../api';
import { ErrorResponse } from '../../../types/errors';

type QesSignaturePathParams = {
    contractId: string;
    signerId: string;
}

interface OwnProps extends TranslationContext {
    children: React.ReactNode;
}

const QesSignatureControllerComponent: FunctionComponent<OwnProps> = (props) => {
    const { children, language } = props;

    const { signerId = '', contractId = '' } = useParams() as QesSignaturePathParams;
    const navigate = useNavigate();
    const CornerstoneApiInstance = useCornerstoneApi();

    const startQesVideoWorkflow: QesSignatureContext['startQesVideoWorkflow'] = async () => {
        try {
            const { data } = await CornerstoneApiInstance.post(startQesWorkflowUrl(signerId));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getQesVideoDocuments = (documentType: QesVideoDocumentType) => {
        return CornerstoneApiInstance.get(documentationUrl({
            qesDocumentationType: documentType,
        }), {
            responseType: 'blob',
            headers: {
                'Accept-Language': languageTypes[language],
            },
        });
    };

    const getQesVideoTermsAndCondition: QesSignatureContext['getQesVideoTermsAndCondition'] = async () => {
        try {
            const { data } = await getQesVideoDocuments(QesVideoDocumentType.TERMS_AND_CONDITIONS);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getQesVideoPrivacyPolicy: QesSignatureContext['getQesVideoPrivacyPolicy'] = async () => {
        try {
            const { data } = await getQesVideoDocuments(QesVideoDocumentType.PRIVACY_POLICY);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const acceptQesVideoDocuments = (documentType: QesVideoDocumentType) => {
        return CornerstoneApiInstance.post(acceptDocumentationUrl(signerId, {
            qesDocumentationType: documentType,
        }), undefined, {
            headers: {
                'Accept-Language': languageTypes[language],
            },
        });
    };

    const acceptQesVideoTermsAndCondition: QesSignatureContext['acceptQesVideoTermsAndCondition'] = async () => {
        try {
            const { data } = await acceptQesVideoDocuments(QesVideoDocumentType.TERMS_AND_CONDITIONS);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const acceptQesVideoPrivacyPolicy: QesSignatureContext['acceptQesVideoPrivacyPolicy'] = async () => {
        try {
            const { data } = await acceptQesVideoDocuments(QesVideoDocumentType.PRIVACY_POLICY);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const requestQesVideoFlow: QesSignatureContext['requestQesVideoFlow'] = async () => {
        try {
            const { data } = await CornerstoneApiInstance.post(qesVideoFlowUrl(signerId));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const requestQesVideoVerification: QesSignatureContext['requestQesVideoVerification'] = async () => {
        try {
            await CornerstoneApiInstance.post(qesVideoVerificationUrl(signerId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const completeSignature: QesSignatureContext['completeSignature'] = async (otp) => {
        try {
            await CornerstoneApiInstance.post(qesCompleteSignature(signerId, { otp: otp.toLowerCase() }));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const navigateToContract = () => {
        navigate(buildUrl(AppRoute.ShowContract, {
            contractId,
        }));
    };

    const navigateToPrivacyPolicy = () => {
        navigate(buildUrl(AppRoute.QesSignaturePrivacyPolicy, {
            contractId,
            signerId,
        }));
    };

    const navigateToStartVideoValidation = () => {
        navigate(buildUrl(AppRoute.QesSignatureStartVideoValidation, {
            contractId,
            signerId,
        }));
    };

    const navigateToCheckingVideoSignature = () => {
        navigate(buildUrl(AppRoute.QesSignatureVideoValidationChecking, {
            contractId,
            signerId,
        }));
    };

    const navigateToVideoValidationFailure = () => {
        navigate(buildUrl(AppRoute.QesSignatureVideoValidationFailed, {
            contractId,
            signerId,
        }));
    };

    const navigateToCheckingVideoSignatureFailure = () => {
        navigate(buildUrl(AppRoute.QesSignatureVideoValidationCheckingFailed, {
            contractId,
            signerId,
        }));
    };

    const navigateToQesOtp = () => {
        navigate(buildUrl(AppRoute.QesSignatureOtp, {
            contractId,
            signerId,
        }));
    };

    const navigateToContractSignedPage = () => {
        const searchQuery = new URLSearchParams();
        searchQuery.append('signSuccess', 'true');
        return navigate({
            pathname: buildUrl(AppRoute.ShowContract, { signerId, contractId }),
            search: searchQuery.toString(),
        });
    };

    return (
        <QesSignatureContextProvider
            value={{
                startQesVideoWorkflow,
                getQesVideoTermsAndCondition,
                getQesVideoPrivacyPolicy,
                acceptQesVideoTermsAndCondition,
                acceptQesVideoPrivacyPolicy,
                requestQesVideoFlow,
                requestQesVideoVerification,
                completeSignature,
                navigateToContract,
                navigateToPrivacyPolicy,
                navigateToStartVideoValidation,
                navigateToCheckingVideoSignature,
                navigateToVideoValidationFailure,
                navigateToCheckingVideoSignatureFailure,
                navigateToQesOtp,
                navigateToContractSignedPage,
            }}
        >
            {children}
        </QesSignatureContextProvider>
    );
};

export const QesSignatureController = withTranslationContext(QesSignatureControllerComponent);
