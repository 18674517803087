/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import {
    Contract,
    ContractPayload,
    ContractState,
    ContractType,
    ContractTypePayload,
    IdDocumentType,
    Placeholder,
    PlaceholderPayload,
    SignatureType,
    Signer,
    SignerPayload,
} from '../../types/contracts';
import { DataOrError, ErrorResponse } from '../../types/errors';
import { PlaceholderHeight, PlaceholderWidth } from '../../constants/contracts';

import ContractsController from './ContractsController';
import { FormValidationError } from '../../utils/validations';
import { ListResponse } from '../../types/general';
import { PurchasePayload } from '../../types/billing';

export interface ContractsContext {
    submitNewContractType(payload: ContractTypePayload): Promise<DataOrError<ContractType, ErrorResponse>>;
    getContractTypes(cursor?: string, limit?: string): Promise<ListResponse<ContractType>>;
    editContractType(contractTypeId: number, payload: ContractTypePayload): Promise<undefined | ErrorResponse>;
    deleteContractType(contractTypeId: number): Promise<undefined | ErrorResponse>;
    validateContractTypeForm(fields: ContractTypePayload): FormValidationError | null;
    getContract(contractId: string): Promise<DataOrError<Contract, ErrorResponse>>;
    getContractPdf(contractId: string): Promise<DataOrError<Blob, ErrorResponse>>;
    getContracts(cursor?: string, limit?: string): Promise<ListResponse<Contract>>;
    createContract(payload: FormData): Promise<DataOrError<Contract, ErrorResponse>>;
    deleteContract(id: string): Promise<DataOrError<void, ErrorResponse>>;
    cancelContract(id: string, notes: string): Promise<DataOrError<void, ErrorResponse>>;
    editContract(contractId: string, payload: Partial<ContractPayload>): Promise<DataOrError<Contract, ErrorResponse>>;
    replaceContract(contractId: string, payload: FormData): Promise<DataOrError<Contract, ErrorResponse>>;
    validateContractForm(fields: Partial<ContractPayload>): FormValidationError | null;
    addNewSigner(contractId: string, payload: SignerPayload): Promise<DataOrError<Signer, ErrorResponse>>;
    updateSigner(contractId: number, signerId: number, payload: SignerPayload): Promise<DataOrError<Signer, ErrorResponse>>;
    checkSignatureAvailability(payload: Partial<SignerPayload>): Promise<boolean>;
    validateSignerForm(fields: SignerPayload): FormValidationError | null;
    deleteSigner(contractId: number, signerId: number): Promise<undefined | ErrorResponse>;
    getSigner(contractId: number, signerId: number): Promise<DataOrError<Signer, ErrorResponse>>;
    createSignaturePlaceholder(contractId: string, payload: PlaceholderPayload): Promise<DataOrError<Placeholder, ErrorResponse>>;
    updateSignaturePlaceholder(contractId: string, placeholderId: number, payload: PlaceholderPayload): Promise<DataOrError<Placeholder, ErrorResponse>>;
    deleteSignaturePlaceholder(contractId: string, placeholderId: number): Promise<undefined | ErrorResponse>;
    getContractPlaceholders(contractId: string): Promise<Placeholder[]>;
    purchaseContract(contractId: string, payload: PurchasePayload): Promise<DataOrError<undefined, ErrorResponse>>;
    createWorkflowContract(workflowId: string, payload: FormData): Promise<DataOrError<Contract, ErrorResponse>>;
}
const contractTypeDefault: ContractType = {
    id: 1,
    name: 'test',
    organizationId: 90000,
    createdDate: new Date(),
    lastModifiedDate: new Date(),
};

const contractDefault: Contract = {
    id: 0,
    name: '',
    organizationId: 0,
    userOwnerId: '',
    signers: [],
    contractType: {
        id: 0,
        name: 'ContractType',
        organizationId: 90000,
        createdDate: new Date(),
        lastModifiedDate: new Date(),
    },
    contractState: ContractState.DRAFT,
    createdDate: '',
    lastModifiedDate: '',
    externalId: '',
    downloadUrl: '',
    transactionId: 1,
    contractStateNotes: '',
    voided: false,
};

const signerDefault = {
    id: 1,
    contractId: 123,
    name: 'Test Signer',
    inviteEmail: 'test@mail.com',
    phoneNumber: '+351912912912',
    inviteCreatorId: '',
    inviteToken: 'token',
    expiresAt: '',
    signedAt: '',
    documentNumber: '',
    documentType: IdDocumentType.IDCARD,
    documentCountry: 'PRT',
    signatureType: SignatureType.QESCMD,
};

const signaturePlaceholderDefault = {
    id: 1,
    signer: signerDefault,
    createdDate: '',
    lastModifiedDate: '',
    contractId: 123,
    page: 1,
    top: 200,
    left: 200,
    height: PlaceholderHeight,
    width: PlaceholderWidth,
};

export const contractsContextDefaultValue: ContractsContext = {
    submitNewContractType: async () => [contractTypeDefault, null],
    getContractTypes: async () => ({ cursor: '', results: [] }),
    editContractType: async () => undefined,
    deleteContractType: async () => undefined,
    validateContractTypeForm: () => null,
    getContract: async () => [contractDefault, null],
    getContractPdf: async () => [new Blob([]), null],
    getContracts: async () => ({ cursor: '', results: [] }),
    createContract: async () => [contractDefault, null],
    editContract: async () => [contractDefault, null],
    replaceContract: async () => [contractDefault, null],
    validateContractForm: () => null,
    addNewSigner: async () => [signerDefault, null],
    updateSigner: async () => [signerDefault, null],
    getSigner: async () => [signerDefault, null],
    checkSignatureAvailability: async () => false,
    validateSignerForm: () => null,
    deleteSigner: async () => undefined,
    createSignaturePlaceholder: async () => [signaturePlaceholderDefault, null],
    updateSignaturePlaceholder: async () => [signaturePlaceholderDefault, null],
    deleteSignaturePlaceholder: async () => undefined,
    getContractPlaceholders: async () => ([]),
    purchaseContract: async () => [undefined, null],
    deleteContract: () => Promise.resolve([undefined, null]),
    cancelContract: () => Promise.resolve([undefined, null]),
    createWorkflowContract: async () => [contractDefault, null],
};

const contractsContextInstance = createContext<ContractsContext | null>(contractsContextDefaultValue);

export const ContractsContextProvider = contractsContextInstance.Provider;
export const ContractsContextConsumer = contractsContextInstance.Consumer;

export const withContractsContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof ContractsContext>> => (props) => (
    <ContractsController>
        <ContractsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </ContractsContextConsumer>
    </ContractsController>
);
